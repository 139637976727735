<template>
  <div class="app-wrapper app-manager">
    <navbar />
    <div class="main-container">
      <sidebar />
      <div class="app-main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./component/navbar.vue";
import sidebar from "./component/sidebar.vue";
export default {
  components: {
    navbar,
    sidebar,
  },
  data() {
    return {};
  },

  created() {},
  methods: {},
};
</script>
