<template>
  <div class="sidetree-container sidetree-manage">
    <div class="sidetree-box">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <div class="side-menu">
          <div
            class="side-menu-title"
            @click="isDesignerCollapse = !isDesignerCollapse"
          >
            <span>我是设计师</span>
            <i
              class="el-icon-arrow-down"
              :class="{ collapse: isDesignerCollapse }"
            ></i>
          </div>
          <ul class="side-submenu" :class="{ collapse: isDesignerCollapse }">
            <li
              :class="{ active: current == '/designer/home/concern' }"
              @click="handleMenuClick(11)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>我的关注</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/home/fans' }"
              @click="handleMenuClick(12)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>我的粉丝</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/home/integral' }"
              @click="handleMenuClick(13)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>我的积分</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/home/download' }"
              @click="handleMenuClick(14)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>我的下载</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/home/likes' }"
              @click="handleMenuClick(15)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>我的点赞</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/home/points' }"
              @click="handleMenuClick(16)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>点赞我的</span>
                </div>
              </div>
            </li>
          </ul>
          <div
            class="side-menu-title"
            @click="isSettingCollapse = !isSettingCollapse"
          >
            <span>个人设置</span>
            <i
              class="el-icon-arrow-down"
              :class="{ collapse: isSettingCollapse }"
            ></i>
          </div>
          <ul class="side-submenu" :class="{ collapse: isSettingCollapse }">
            <li
              :class="{ active: current == '/designer/setting/base' }"
              @click="handleMenuClick(21)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>基本信息</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/setting/modifyPwd' }"
              @click="handleMenuClick(22)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>修改密码</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/setting/phone' }"
              @click="handleMenuClick(23)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>绑定手机号</span>
                </div>
              </div>
            </li>
            <li
              :class="{ active: current == '/designer/setting/wxcode' }"
              @click="handleMenuClick(24)"
            >
              <div class="menu-item-wrapper">
                <div class="text-wrapper padding20">
                  <span>绑定微信号</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-scrollbar>
    </div>
    <!-- <div class="community-btn" @click="$router.push('/community/home')">
      <img src="../../../assets/images/icon/icon_shequ@2x.png" alt="" />
      <span class="text">社区</span>
    </div> -->
  </div>
</template>

<script>
import CForm from "@/components/CForm/index.vue";
export default {
  components: { CForm },
  data() {
    return {
      // current: 10,
      isDesignerCollapse: false,
      isSettingCollapse: false,
    };
  },
  computed: {
    current() {
      return this.$route.path;
    },
  },
  created() {},
  methods: {
    handleMenuClick(menu) {
      // this.current = menu;
      switch (menu) {
        case 11:
          this.$router.push("/designer/home/concern");
          break;
        case 12:
          this.$router.push("/designer/home/fans");
          break;
        case 13:
          this.$router.push("/designer/home/integral");
          break;
        case 14:
          this.$router.push("/designer/home/download");
          break;
        case 15:
          this.$router.push("/designer/home/likes");
          break;
        case 16:
          this.$router.push("/designer/home/points");
          break;
        case 16:
          this.$router.push("/designer/home/points");
          break;
        case 21:
          this.$router.push("/designer/setting/base");
          break;
        case 22:
          this.$router.push("/designer/setting/modifyPwd");
          break;
        case 23:
          this.$router.push("/designer/setting/phone");
          break;
        case 24:
          this.$router.push("/designer/setting/wxcode");
          break;
      }
    },
  },
};
</script>
