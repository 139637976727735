<template>
  <div class="navbar">
    <img src="../../../assets/images/logo.png" alt="" class="user-logo" @click="$router.push('/community/collectionList')"/>

    <div class="nav-user">
      <el-dropdown trigger="click" @command="handleToggleRole">
        <span class="el-dropdown-link">
          <img class="user-avatar" :src="avatar" alt="" /> 
          <span class="user-name">{{ username}}</span>
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="workspace">工作台</el-dropdown-item>
          <el-dropdown-item command="manager" v-if="admin">管理中心</el-dropdown-item> -->
          <el-dropdown-item command="exit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      @close="handleDialogClose"
      :lock-scroll="false"
    >
      <c-form
        v-model="handleData"
        ref="cForm"
        :formRules="formRules"
        :formItems="formItems"
        @submit="submit"
        @resetFields="resetFields"
      >
      </c-form>
    </el-dialog>
  </div>
</template>

<script>
import CForm from "@/components/CForm/index.vue";
import Configs from "../data/config";
import { Chrome } from "vue-color";
import { EventBus } from "@/EventBus";
export default {
  components: { CForm, "chrome-picker": Chrome },
  data() {
    let { formItems, formRules } = Configs(this);
    return {
      SUCCESS_CODE: 0,
      formItems,
      formRules,
      username: "", // 用户名
      admin: false, // 是否管理员
      dialogFormVisible: false,
      handleData: {
        password: "",
        newPassword: "",
        secondPassword: "",
      },

      avatar: "",
    };
  },
  created() {
    let nickname = localStorage.getItem("nickname");
    if (nickname) this.username = localStorage.getItem("nickname");
    this.admin = JSON.parse(localStorage.getItem("userInfo")).admin;
    this.avatar = JSON.parse(localStorage.getItem("userInfo")).photo;
  },
  mounted() {},
  methods: {
    // 切换角色或退出登录
    handleToggleRole(command) {
      console.log(command);
      if (command == "exit") {
        // 退出登录，清空localStorage
        localStorage.clear();
        this.$router.replace("/login");
        // 清除vuex中的数据
        // this.$store.commit("auth/resetState");
      } else if (command == "workspace") {
        this.$router.push("/home");
      } else if (command == "manager") {
        this.$router.push("/manager");
      }
    },
    handleDialogClose() {
      this.$refs.cForm.$refs.cForm.resetFields();
      this.dialogFormVisible = false;
    },
    resetFields() {
      this.dialogFormVisible = false;
    },
    // 修改密码
    submit() {
      this.$http
        .post("/user/modifyPassword", this.handleData)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>

</style>

